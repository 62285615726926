<template>
    <v-main>
        <home-cookies />
        <check-org-invite />
        <router-view :key="$route.fullPath" />
    </v-main>
</template>

<script>
export default {
    name: `BaseView`,
    components: {
        HomeCookies: () => import(`@/layouts/home/cookies`),
        CheckOrgInvite: () => import(`@/components/branches/check_invite`)
    }
}
</script>
